import React, { Component } from "react";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { 
  Box,
  Grid,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { AuthContext } from "../../components/Auth/AuthDataProvider";

import { ReactComponent as MenuIcon } from '../../assets/img/icons/menu.svg';
import { ReactComponent as ImportIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import MUIDataTable from "mui-datatables";
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import wmsApi from "../../api/wmsApi";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import RemoveDialog from '../../components/Dialog/RemoveDialog';
import CustomDialog from "../../components/Dialog/CustomDialog";
import CustomInput from "../../components/CustomFields/CustomInput";
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker';
import DropdownMenu from "../../components/Dropdown/DropdownMenu";
import ImportOrderDialog from "./Partial/ImportOrderDialog";
import Select from 'react-select';
import { Row, Container, Label, Card, CardBody, Col } from "reactstrap";

import moment from 'moment';

import apiUtil from "../../api/apiUtil";
import customerApi from "../../api/Customer";
import salesOrderApi from "../../api/SalesOrder";
import { RoleAccessService } from "../../data/role-access";
import axios from 'axios';

const { 
  REACT_APP_WMS_API_URL
} = process.env;

class SalesOrderList extends Component {
  static contextType = AuthContext
  constructor(props) {
    super(props);

    let rangeDate = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
    };

    let grantedAccess = new RoleAccessService();
    this.state = {
      grantedAccess : grantedAccess,
      currentRole: window.localStorage.getItem('current_role') || null,
      rangeDate: rangeDate,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,
      tableState: {
        page: 0,
        count: 1,
        rowsPerPage: 15,
        sortOrder: {}
      },
      data: [],
      isLoading: false,
      customerList: [],
      selectedCustomer: {
        value: '0',
        name: '',
        label: ''
      },
      isFilterOpen: false,
      warehouseList: [],
      selectedWarehouse: {
        id: '',
        name: ''
      },
      filter: {
        customerId: "",
        text: 'Choose..',
        field: 'query',
        value: ''
      }
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

    changePage = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  search = (page, query) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.search = query;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  customSelection = (data, selected) => {
    let selected_data = []
    selected.data.forEach(s => {
      let da = data.filter(d => s.index == d.index)
      selected_data.push(da)
    })
    let selectedIds = selected_data.map(sd => sd[0].data[0])

    this.setState({selectedIds})
    return selectedIds
  }

  getData = async () => {
    let customerId = this.state.selectedCustomer.id? this.state.selectedCustomer.id : '';
    if (!customerId) {
      if (!this.state.selectedCustomer.id) {
        // apiUtil.toast('No customer selected.', 'error');
        // return false;
        customerId= "";
      }else{
        customerId = this.state.selectedCustomer.id;
      }
    }

    let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
    let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

    this.setState({ isLoading: true });

    let params = {
      customer_id: customerId,
      optional_filter_by: this.state.filter.field,
      optional_query: this.state.filter.value,
      warehouse_id: this.state.selectedWarehouse.id? this.state.selectedWarehouse.id : '',
      start_date: startDate,
      end_date: endDate,
    };

    await salesOrderApi.get(params).then(data => {
      this.setState({
        isLoading: false,
        data: data.data
      });
    }).catch(error => console.log(error)); 
    
  }

  getCustomer = async () => {
    let { accessToken } = this.context
    await customerApi.get('', accessToken).then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        customerList.push({
          value: ' ',
          label: 'Select Company',
          name: 'Select Company'
        })
        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_name,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });

        let customerFilter = window.localStorage.getItem('wms_customerFilter') || false;

        if (customerFilter) {
          customerFilter = JSON.parse(customerFilter);
          this.setState({
            selectedCustomer: {
              id: customerFilter.id,
              name: customerFilter.name,
              label: customerFilter.label
            }
          });
          // this.getData(customerFilter.id);
        }
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  /* EXPORT API */
  callExportXLSApi = () => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
        downloadIsLoading: true
    }, () => {
      let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
        handling_in: true
      };

      wmsApi.exportXLS('admin/api/export/sale_orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (response) => {
            apiUtil.saveToFile(response, `Outbound Orders ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.xls`);
    
            this.setState({
              downloadIsLoading: false,
            });
          }, (error, type) => {
            this.setState({
              downloadIsLoading: false,
            });
          });
      });
    });
  }
  /* END EXPORT API */

   /* EXPORT API */
   callExportPDFApi = () => {
    let startDate = moment(this.state.startDate).format(apiUtil.getDefaultDateFormat());
          let endDate = moment(this.state.endDate).format(apiUtil.getDefaultDateFormat());
          let pdf = new jsPDF("p", "mm", "a4");
          var col = ['Reference No', 'Order Date', 'Company Name', 'Company Code', 'Recipient Name', 'Warehouse', 'Quantity', 'SKU no', 'Batch ID','Remarks'];
          var rows = [];
           let data = this.state.data;
           data.forEach(element => {  
            let total_sku = '';
            let total_batch_id = '';    
            let Quantity = 0
            element.order_items.forEach((item)=>{ 
              if(item.item && item.item.hasOwnProperty('sku')) {
              total_sku += item.item.sku + ','
              }
              if (item.item && item.item.batch_id) {
                total_batch_id += item.item.batch_id + ','
              }
              Quantity += item.item_qty
            })
            total_sku =  total_sku.slice(0, -1)
            total_batch_id = total_batch_id.slice(0, -1)
            var temp = [element.reference_no,  moment(element.created_at).format('YYYY-MM-DD'), element.customer? element.customer.company_name: '', element.customer? element.customer.company_code: '',  element.end_user? element.end_user.end_user: '', element.order_items && element.order_items.items? element.order_items[0].item.warehouse.name: '',element.order_items?  Quantity: '', element.order_items? total_sku: "", element.order_items? total_batch_id: "", element.total_kg, element.total_package, element.remarks];
            
            rows.push(temp);
            });        
    
            pdf.autoTable(col, rows, { 
              // startY: 10,
              margin: { horizontal: 10 },
              styles: { overflow: "linebreak" },
              bodyStyles: { valign: "top" },
              columnStyles: { email: { columnWidth: "wrap" } },
              theme: "striped",
              // showHead: "everyPage",
              didDrawPage: function (data) {
                // Header
                pdf.setFontSize(16);
                pdf.setTextColor("#161C22");
        
                // Footer
                let str = "" + pdf.internal.getNumberOfPages();
                pdf.setFontSize(10);
        
                // jsPDF 1.4+ uses getWidth, <1.4 uses .width
                let pageSize = pdf.internal.pageSize;
                let pageHeight = pageSize.height
                  ? pageSize.height
                  : pageSize.getHeight();
                // pdf.text("Footer text", data.settings.margin.left, pageHeight - 10);
                pdf.text(575, 830, str);
              } });

            pdf.save(`Outbound Orders ${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}.pdf`);
  }
  /* END EXPORT API */

  toggleFilter = () => {
    let isFilterOpen = this.state.isFilterOpen;

    this.setState({
      isFilterOpen: !isFilterOpen
    });
  }

  handleWarehouseChange = (event) => {
    this.setState({
      selectedWarehouse: {
        id: event.value,
        name: event.name
      }
    }, ()=> {
      this.getData();
    });
    
  }

  handleCustomerChange = (event) => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name,
        label: event.label
      }
    }, () => {
      this.getData();
    });

    window.localStorage.setItem('wms_customerFilter', JSON.stringify({
      id: event.value,
      name: event.name,
      label: event.label
    }));

    this.getData(event.value);
  }

  handleFilterChange = (event) => {
    let filter = this.state.filter;
    filter.text = event.target.innerHTML;
    filter.field = event.target.value;

    this.setState({
      filter: filter
    });
  }

  handleFilterFieldChange = (event) => {
    let filter = this.state.filter;
    filter.value = event.target.value;
    
    this.setState({
      filter: filter
    });
  }

  componentDidMount = () => {
    document.title = "All Outbound Orders | WMS";

    this.setState({
      selectedCustomer: ''
    }, ()=> {
      this.getData();
    });

    window.scrollTo(0, 0);
    this.getCustomer();
    this.getWareHouse();
  }

  getWareHouse = (callback = null) => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
      isLoading: true
    }, () => {
      let param = {
      };

      wmsApi.read('warehouses', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let warehouseList = []
            if (data.result.length > 0) {
              warehouseList.push({
                value: null,
                label: 'Select Warehouse',
                name: 'Select Warehouse'
              })
              data.result.forEach((val) => {
                warehouseList.push({
                  value: val.id,
                  label: val.name,
                  name: val.name
                });
              });
            }
            this.setState({
              warehouseList: warehouseList,
              isLoading: false,
            });
            if (callback) {
                callback(data)
            }
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              isLoading: false,
            });
          });
      });
    });
  }

  callChangeStatusApi = (id, status, callback = null) => {
    const  accessToken = localStorage.getItem("access_token");

    this.setState({
      isLoading: true
    }, () => {
      let param = {
        order_status: status,
        order_reason: this.state.order_reason
      };

      wmsApi.update('orders/change_status/' + id, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                apiUtil.toast(error, 'error');
                callback();
              }
            });
          });
      });
    });
  }
  customCompleteDialog = () => {
    return <CustomDialog 
      open={this.state.completeDialog}
      title={'Complete'}
      onClose={() => {
        this.setState({
          completeDialog: false,
          openDialogItem: null,
          order_reason: null
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callChangeStatusApi(row, "Completed", (data) => {
          this.setState({
            completeDialog: false,
            openDialogItem: null,
            order_reason: null
          }, () => {
            this.getData();
            if(data) {
              apiUtil.toast('Successfully Completed', 'success');
            }
            
          });
        });
      }}
    >
      <Box>
        <Grid item xs={12}>
            <CustomInput
                label={'Reasons'}
                className="input-label--horizontal"
                value={this.state.order_reason}
                multiline={true}
                rows={3}
                onChange={e => {
                    this.setState({
                      order_reason: e.target.value
                    })
                }}
            />
        </Grid>

      </Box>
    </CustomDialog>
  }

  customVoidDialog = () => {
    return <CustomDialog 
      open={this.state.openVoidDialog}
      title={'Void'}
      onClose={() => {
        this.setState({
          openVoidDialog: false,
          openDialogItem: null,
          order_reason: null
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callChangeStatusApi(row, "Void", (data) => {
          this.setState({
            openVoidDialog: false,
            openDialogItem: null,
            order_reason: null
          }, () => {
            this.getData();
            if(data){
              apiUtil.toast('Successfully Void', 'success');
            }
            
          });
        });
      }}
    >
      <Box>
        <Grid item xs={12}>
            <CustomInput
                label={'Reasons'}
                className="input-label--horizontal"
                value={this.state.order_reason}
                multiline={true}
                rows={3}
                onChange={e => {
                    this.setState({
                      order_reason: e.target.value
                    })
                }}
            />
        </Grid>

      </Box>
    </CustomDialog>
  }

  removeDialog = () => {
    return <RemoveDialog
    modal={this.state.modal}
    onRemove={(isRemove) => {
      if (isRemove) {
        let data_ = this.state.data.filter(r => r.id !== this.state.modalItem);

        let errorMessage = "";
        let status = 0;

        const URL = REACT_APP_WMS_API_URL + "admin/api/orders/" + this.state.modalItem;
        const access_token = localStorage.getItem("access_token");

        axios.delete(
          URL,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${access_token}`
            }
          }).then(data => {
            if (data.data.status === true) {
              apiUtil.toast(data.data.message, 'success');
            } else {
              apiUtil.toast(data.data.message, 'warning');
            }
            
            this.getData();
          })
        .catch( error => {
          status = error.response.data.status;
          errorMessage = error.response.data.error;
          apiUtil.toast(errorMessage, 'error');
        });

        this.setState({
          data: data_
        });
      }

      this.setState({
        modal: false,
        modalItem: null
      });
    }}
    />
  }


  deleteData = id => {
    this.setState({
      modal: true,
      modalItem: id
    });
  }

  callImportApi = (row, callback = null) => {
    const  accessToken = localStorage.getItem("access_token");
    let param = {
      _json : row
    }

    this.setState({
      isLoading: true
    }, () => {
      wmsApi.create('create/sale_orders/multiple', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              apiUtil.toast(error, 'error');
            });
          });
      });
    });
  }

  importDialog = () => {
    return <ImportOrderDialog
      modal={this.state.importModal}
      onImport={(form) => {
        this.setState({
          importModal: false
        }, () => {
          this.callImportApi(form, (data) => {
            let all_jobs = (form && form.length > 0) ? form.length : 0;
            let failed_orders  = (data && data.failed_orders && data.failed_orders.length > 0) ? data.failed_orders.length : 0;
            let invalid_items = (data && data.invalid_items && data.invalid_items.length > 0) ? data.invalid_items.length : 0;
            let saved_orders  = (data && data.saved_orders  && data.saved_orders .length > 0) ? data.saved_orders .length : 0;
            
            if(failed_orders > 0){
              apiUtil.toast(failed_orders + ' of ' + all_jobs + ' orders have not successfully imported', 'error');
            }

            if(invalid_items > 0){
              apiUtil.toast(invalid_items + ' of ' + all_jobs + ' items does not match, orders have not successfully imported',  'error');
            }

            if(saved_orders > 0){
              apiUtil.toast(saved_orders + ' of ' + all_jobs + ' orders successfully imported', 'success');
            }

            this.getData();

            this.setState({
              importModal: false
            });
          });
        });
      }}
      onClose={() => {
        this.setState({
          importModal: false
        });
      }}
    />
  }

  render() {
    const columns = [
      {
        name:"id",
        label: "Reference No",
        cellStyle: {
          minWidth: 200,
          maxWidth: 200
        },
        options: {
          customBodyRender: (value) => {
            let data = this.state.data.filter(dd => dd.id == value)
            return <a href={'/sales-order/order-detail?id='+ data[0].id }>{data[0].reference_no}</a>
          }
        }
      },
      {
        name: "handling_in_date",
        label: "Date",
        cellStyle: {
          minWidth: 200,
          maxWidth: 200
        },
        options: {
          customBodyRender: (value) => {
            return moment(value).format('DD-MMM-YYYY');
          }
        }
      },
      // {
      //   name: "id",
      //   label: "Total Weight",
      //   cellStyle: {
      //     minWidth: 200,
      //     maxWidth: 200
      //   },
      //   options: {
      //     customBodyRender: (value) => {
      //       let data = this.state.data.filter(dd => dd.id == value)
      //       let qty_total = 0.00;
      //       data[0].order_items && data[0].order_items.map ((item)=>
      //         qty_total += parseFloat(item.weight)
      //       )
      //       return qty_total
      //     }
      //   }
      // },
      {
        name: "id",
        label: "Recipient Name",
        minWidth: 300,
        maxWidth: 300,
        options: {
          customBodyRender: (value) => {
            let data = this.state.data.filter(dd => dd.id == value)
            return data && data[0].end_user && data[0].end_user.end_user
          }
        }
      },
      {
        name: "id",
        label: "Warehouse Name",
        options: {
          customBodyRender: (value) => {
            let data = this.state.data.filter(dd => dd.id == value)[0]
            return data && data.order_items && data.order_items.length > 0 && data.order_items[0].item && data.order_items[0].item.warehouse.name
          }
        }
      },
      {
        name: "id",
        label: "Quantity",
        options: {
          customBodyRender: (value) => {
            let data = this.state.data.filter(dd => dd.id == value)[0]
            let qty = 0
            data.order_items.forEach(dd => {
               qty += dd.item_qty
            });
            return qty
          }
        }
      },
      {
        name: "order_status",
        label: "Order Status",
        cellStyle: {
          minWidth: 300,
          maxWidth: 300
        },
        options: {
          customBodyRender: (value) => {
            if(value && value.status == 'Confirmed') {
              return <div style={{color:'#AE0264', fontSize: '12px'}} className="fw-bold">CONFIRMED</div>
            } else if (value && value.status == 'Void'){
              return <div style={{color:'#EA5455', fontSize: '12px'}} className="fw-bold">VOID</div>
            } else if (value && value.status == 'Returned'){
              return <div style={{color:'#FF6600', fontSize: '12px'}} className="fw-bold">RETURNED</div>
            }else if (value && value.status == 'Completed'){
              return <div style={{color:'#5CB85C', fontSize: '12px'}} className="fw-bold">COMPLETED</div>
            } else {
              return ''
            }
           
          }
        }
      },
      // {
      //   name: "hawb",
      //   label: "Hawb"
      // },
      // {
      //   name: "container_receipt",
      //   label: "CR/PR/SO"
      // },
      {
        name: "id",
        label: "Actions",
        cellStyle: {
          minWidth: 350,
          maxWidth: 350
        },
        options: {
          customBodyRender: (id) => {
            let data = this.state.data.filter(dd => dd.id == id)[0]
            return (
              <Box>
                <DropdownMenu
                  text={'Actions'}
                  className={'status-btn ' + id }
                  rightIcon={<ArrowDownIcon />}
                  color={"secondary"}
                  items=
                  {[
                  // (this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Change Outbound Order Status")) ? {text:"Void"}: '', 
                  (this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Change Outbound Order Status")) && (data.order_status && data.order_status.status != 'Returned')? {text:"Complete"}:'',
                  (data.order_status && data.order_status.status == "Confirmed") && (this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Outbound Order Edit")) ?{text:"Edit"}:'',
                  (this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Outbound Order Delete")) ?{text:"Delete"}:'',]}
                  onClick={(item) => {
                    switch(item.text) {
                      // case "Void":
                      //   this.setState({
                      //       openVoidDialog: true,
                      //       openDialogItem: id
                      //   })
                      //   break;
                      case "Complete":
                        this.setState({
                            completeDialog: true,
                            openDialogItem: id
                        })
                        break; 
                      case "Edit":
                        this.props.history.push('/sales-order/edit?id=' + id)
                        
                        break;
                      case "Delete":
                        this.deleteData(id)
                        break;
                      default:
                        break;

                  }
                  }}
                />

              </Box>
              
              // <div className="btn-group">
              //   <a href={'./edit?id=' + id}>
              //     <EditIcon className="fix-icon-color" />
              //   </a>
              //   <button type="button" onClick={() => this.deleteData(id)}>
              //     <DeleteIcon />
              //   </button>
              // </div>
            );
          }
        }
      }
    ];

    const options = {
      selectableRows: "multiple",
      rowsPerPage: this.state.tableState.rowsPerPage,
      rowsPerPageOptions: [10, 15, 50, 100],
      responsive: "standard", // standard | vertical | simple
      serverSide: true,
      download: false,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder);
            break;
          case 'sort':
            this.sort(tableState.page, tableState.sortOrder);
            break;
          case 'rowSelectionChange':
            this.customSelection(tableState.data, tableState.selectedRows);
            break;
          default:
            console.log('action not handled.');
        }
      },
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <div className="btn-group" style={{margin:"5px"}}>
        <button type="button" 
        onClick={() => {
            let selectedRow = this.state.selectedIds;
            let ids = '';
            selectedRow.map((id) => { ids += id +','; });
            ids = ids.slice(0, -1);
            this.setState({
              modal: true,
              modalItem: ids
            })
        }}>
          <DeleteIcon />
        </button>
      </div>
      ),
      downloadOptions:{filename: 'Outbound Order - '+this.state.selectedCustomer.name+'.csv', separator: ','},
      onDownload: (buildHead, buildBody, columns, data) => {
          columns.splice(-1,1);
          data.map(function(item, index){ 
            item.data.splice(-1,1); 
            item.data[1] = moment(item.data[1]).format('DD-MMM-YYYY');
          });
          return "\uFEFF" + buildHead(columns) + buildBody(data); 
      } 
    }

    return <Box className="dashboard-page">
      <StickyPageHeader>
      <Container>
        <Row className="d-flex align-items-center">
          <Col xs={12} sm={12} lg={6}>

          <BreadCrumb />
          <h1 className="page-title">Outbound Order</h1>
          </Col>
          <Col xs={12} sm={12} lg={6}>
          <div className='d-flex justify-content-end align-items-center'>
            <CustomDateRangePicker
              className={'select-dates-step width-adjust-time-picker mx-3'}
              range={this.state.rangeDate}
              onChange={(range) => {
                localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));

                this.setState({
                  rangeDate: range,
                  startDate: range.startDate,
                  endDate: range.endDate
                }, () => {
                  this.getData()
                });
              }}
            />
            {this.state.grantedAccess.isPermissionActionAllowed(this.state.currentRole, "Outbound Order Add") && <IconButton className="btn-theme btn-theme-1 py-2" label="Add New" icon="add" onClick={() => this.props.history.push('/sales-order/create')} /> }
            <label class="dropdown me-3 py-2 col-xs-6">
                  <div class="dd-button-rr secondary">
                  <MenuIcon width="26px" height="auto" />
                  </div>
                  <input type="checkbox" class="dd-input" id="test" />
                  <ul class="dd-menu">
                      
                      {/* <li onClick={()=> this.setState({importModal:true})}><ImportIcon width="18px" height="auto" className="mx-2"/> Import Outbound</li> */}
                      {/* <li><a href="./import-sale-orders" style={{color: "#000"}}><ImportIcon width="18px" height="auto" className="mx-2"/> Import Sale Orders</a></li> */}
                      <li onClick={()=> this.callExportXLSApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export CSV</li>
                      <li onClick={()=> this.callExportPDFApi()}><ExportIcon width="18px" height="auto" className="mx-2"/> Export PDF</li>
                  </ul>
            </label>
            </div>
          </Col>
        </Row>
    
        </Container>
      </StickyPageHeader>

      <Card>
        <CardBody>
        <Row>
             <Col className="col-12 px-4 d-flex" >
               <div className="my-2 w-50">
               <Label >
                  Company Name
                </Label>
               </div>
               <div className="my-2 w-50 ms-3">
                  <Label >
                    Warehouse
                  </Label>
               </div>
            </Col>
            <Col className="col-12 px-4 d-flex">
              <div className="my-2 w-50 ">
                <Select className="option-select" value={this.state.selectedCustomer} options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
              </div>
              <div className="my-2 w-50 ms-3">
              <Select className="option-select" value={{label: this.state.selectedWarehouse.name, value: this.state.selectedWarehouse.id}} options={this.state.warehouseList} onChange={(e) => this.handleWarehouseChange(e)} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      
      <Card className="pt-0">
        <CardBody>
          <Row>
            <Col md="12">
              <MUIDataTable
                className="sale-order-list left-select-box"
                title={
                  <Typography variant="h6">
                    Outbound Order
                    {this.state.isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
                  </Typography>}
                data={this.state.data}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {this.removeDialog()}
      {this.customCompleteDialog()}
      {this.customVoidDialog()}
      {this.importDialog()}
    </Box>;
  }
}

export default SalesOrderList;